<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <BaseCard title="Riwayat Restock">
        <template #action>
          <base-button to="pusat-ke-sc/tambah" type="button">
            <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
            Tambah Restock
          </base-button>
        </template>
        <div class="flex flex-col">
          <div class="mt-2 flex items-end justify-end space-x-2">
            <base-input label="Status Faktur">
              <base-select
                v-model="order_status"
                @change="filterByCompleted"
                :options="[
                  {
                    label: 'Semua',
                    value: 'Draft,Diproses Gudang Pengeluaran',
                  },
                  {
                    label: 'Valid',
                    value: 'Diproses Gudang Pengeluaran',
                  },
                  {
                    label: 'Draft',
                    value: 'Draft',
                  },
                ]"
              />
            </base-input>
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div class="py-2 sm:px-6 lg:px-8">
              <Datatable
                :total="getOrders.meta.page.total"
                :perPage="getOrders.meta.page.perPage"
                :currentPage="getOrders.meta.page.currentPage"
                @pagechanged="onPageChangeOrder"
                :is-empty="!getOrders.data.length"
                cursor
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        @click="handleSort('origin_code', sort.origin_code)"
                        scope="col"
                        class="flex cursor-pointer gap-x-4 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Restock
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Kode SC/Kode Gudang
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Status Faktur
                      </th>
                      <th
                        @click="handleSort('order_status', sort.order_status)"
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        <div class="flex cursor-pointer justify-center gap-x-4">
                          Status Order
                        </div>
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <div v-if="isLoading" class="flex items-center text-center">
                    <loading></loading>
                  </div>
                  <tbody v-if="getOrders.data.length">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrders.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click.prevent="changeSale(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        <order-code-column :value="data" />
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{
                          getRelationships(
                            data.relationships['destination-office'].data.id
                          )?.attributes.code
                        }}
                        /
                        {{
                          getRelationships(
                            data.relationships['origin-office'].data.id
                          )?.attributes.code
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <base-badge>
                          {{ data.attributes.order_status }}
                        </base-badge>
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <base-badge>
                          {{ data.attributes.warehouse_status }}
                        </base-badge>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
        </div>
      </BaseCard>

      <OrderDetailModal
        :visible="openDetail"
        :order="getOrder.data"
        :editable="true"
        :deleteable="false"
        :with-payments="true"
        with-bonus
        validation-ignore-payment-status
        @edit="(id) => $router.push(`pusat-ke-sc/${id}`)"
        @close="openDetail = !openDetail"
        @refresh="handleValidated"
        :deleteable-payment-method="!getOrder.data.attributes.is_paid"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';
import OrderCodeColumn from '@/components/order/order-code-column.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    OrderDetailModal,
    BaseSearch,
    OrderCodeColumn,
  },

  data() {
    return {
      search: null,
      order_status: 'Draft',
      openDetail: false,
      dataPenjualan: {},
      sort: {
        origin_code: false,
        warehouse_status: false,
        order_status: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      isLoading: 'orders/getLoading',
    }),
    fetchOrdersParams() {
      return {
        'page[limit]': 5,
        search: this.search,
        'filter[order_status]': this.order_status,
        include: 'destination-office,origin-office,order',
        'filter[destination_office_category_id]': 3,
        fields: {
          orders:
            'destination-office,canceled_by_user_id,canceled_from,warehouse_status,origin-office,createdAt,updatedAt,deletedAt,origin_code,destination_code,final_price,is_valid_for_payment,destination_office_code,origin_warehouse_code,order_status,has_bundle,is_minus_bv',
        },
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      updateOrder: 'orders/updateOrder',
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
    }),
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getClass(attribut) {
      return {
        'bg-yellow-100 text-yellow-800':
          attribut === 'Diproses Gudang' ||
          attribut === 'Diproses Kasir' ||
          attribut === 'Diproses Gudang Pengeluaran',
        'bg-blue-100 text-blue-800':
          attribut === 'Dikirim' || attribut === 'Persiapan',
        'bg-red-100 text-red-800':
          attribut === 'Ada Susulan' ||
          attribut === 'Belum Masuk Gudang' ||
          attribut === 'Draft',
        'bg-green-100 text-green-800': attribut === 'Selesai',
      };
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...page,
        ...this.fetchOrdersParams,
      });
    },
    filterByCompleted() {
      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    },
    changeSale(data) {
      this.openDetail = !this.openDetail;
      this.fetchOrder({ id: data.attributes.order_id }).then(() => {
        this.dataPenjualan = this.getOrder.data;
      });
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'orders',
          id: data.id,
          attributes: {
            is_valid_for_payment: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrders({
            ...this.fetchOrdersParams,
          });
        }
      });
    },
    handleValidated() {
      this.openDetail = false;
      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    },
    handleSort(column) {
      this.sort[column] = !this.sort[column];

      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    },
  },
  created() {
    this.fetchOrders({
      ...this.fetchOrdersParams,
    });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
